import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {Container, Row, Col} from "react-bootstrap"

class FellRunnning extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Fell Runnning" />
        <Container>
          <Row>
            <Col sm={12}>
            <h1 className="pt-5">Want to go fell runnning in The Lake District?</h1>
            <h5>Here are some useful resources:</h5>
            <ul>
              <li><a href="https://www.theguardian.com/travel/2014/may/12/wild-running-routes-lake-district">Fell running in The Lake District: six routes to try - The Guardian</a></li>
              <li><a href="https://www.cicerone.co.uk/trail-and-fell-running-in-the-lake-district">Trail and Fell Running in the Lake District - Cicerone</a></li>
              <li><a href="https://fellrunner.org.uk/races.php?region=2">Races in the Lake District - Fellrunner.org.uk</a></li>
            </ul>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default FellRunnning

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
